
/* --- Notes --- */


/* --- Fonts --- */

@font-face {
    font-family: 'Ardela Edge';
    src: url('/assets/fonts/ardela-edge.woff2') format('woff2'),
         url('/assets/fonts/ardela-edge.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}