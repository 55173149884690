/* --- Notes --- */
// used for craft to enable inline lightboxes



/* --- Start SCSS --- */

.wysiwyg {
  h4 {
    @include p;
    font-weight: 700;
    margin-bottom: 10px;
  }

  h3 {
    @include p-big;
    text-transform: none;
    font-family: $body;
    margin-bottom: 20px;
    margin-top: 25px;
  }

  p {
    @include p;

    &+p {
      margin-top: 10px;
    }

    &+h4 {
      margin-top: 25px;
    }
  }

  strong {
    font-weight: 700;
  }

  ul,
  ol {
    @include p;
    margin: 10px 0 15px 15px;

    ul,
    ol {
      margin-top: 10px;
      margin-bottom: 15px;
    }

    li+li {
      margin-top: 8px;
    }
  }

  ul {
    list-style: disc;
  }

  ol {
    list-style: roman;
  }

  a {
    color: $d-grey;
    text-decoration: underline;

    @media (hover) {
      &:hover {
        color: $l-grey;
      }
    }
  }

  hr {
    border: 0;
    border-top: 1px solid $m-grey;
    margin: 60px 0;
  }

  blockquote {
    @include font(19px, 25px, $m-grey, 400);
    padding: 20px 40px;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
    max-height: 640px;
    margin: 0 auto;
  }

  figure {
    padding: 40px;
    margin: 0 auto;
  }

  figcaption {
    @include p-small;
    color: $m-grey;
    padding-top: 10px;
  }

  iframe {
    aspect-ratio: 16 / 9;
    width: 100% !important;
    height: auto !important;
  }


}



/* --- TABLET STYLES - SM --- */
@media only screen and (min-width: $sm-width) {}



/* --- TABLET STYLES - MD --- */
@media only screen and (min-width: $md-width) {}



/* --- DESKTOP STYLES - LG --- */
@media only screen and (min-width: $lg-width) {}



/* --- LARGE SCREEN STYLES - XL --- */
@media only screen and (min-width: $xl-width) {}