/* --- Notes --- */


/* --- Form elements --- */

form {
  display: grid;

  &.processing {
    cursor: wait;
    * { pointer-events: none; }
  }

  &.success {
    cursor: not-allowed;
    * { pointer-events: none; }
  }
}

input,
select,
textarea {
  box-sizing: border-box;
  padding: 8px 10px;
  border: 0;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  font-family: $body;
  @include font(12px, 14px, $d-grey, 700);

  // hide FF red glow
  &:required,
  &:invalid {
    box-shadow: none;
  }

  @include placeholder {
    color: $d-grey;
  }

  &:focus {
    outline: none;
  }
}


input {
  &[type=checkbox] {
    display: none;

    &:hover:not(:checked)+label:after {
      opacity: 0.2;
    }

    &:checked+label:after {
      opacity: 1;
    }

    +label {
      @include p;
      display: block;
      position: relative;
      padding-left: 30px;
      cursor: pointer;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: -1px;
        width: 20px;
        height: 20px;
        border: 1px solid $l-grey;
        border-radius: 50%;
      }

      &:after {
        content: '';
        position: absolute;
        left: 3px;
        top: 2px;
        width: 14px;
        height: 14px;
        background: $d-grey;
        opacity: 0;
        border-radius: 50%;
      }
    }
  }

  &[type=file] {
    color: $d-grey;
    position: relative;
    padding-left: 0px;
    margin-right: auto;
    cursor: pointer;

    &::file-selector-button {
      background-color: $white;
      border: none;
      outline: none;
      padding: 8px 10px;
      margin-right: 12px;
      @include font(12px, 14px, $d-grey, 700);
      text-transform: uppercase;
      cursor: pointer;

      &:hover {
        background-color: rgba($white, 0.65);
      }
    }
  }
}



textarea {
  min-height: 150px;
  resize: none;
}


.validation {
  padding: 20px 0 0 0;
  border-top: 1px solid $black;
  @include p-small;

  li {
    &:nth-of-type(1) {
      @include p;
      font-weight: 700;
      margin-bottom: 4px;
    }

    &+li {
      margin-top: 2px;
    }
  }
}


// for use with invisible recaptcha byline
.recaptcha-byline {
  margin-top: 12px;
  @include font(8px,10px,$l-grey,400);

  a {
    color: $l-grey;

    @media (-moz-touch-enabled: 0),
    (hover: hover) {
      &:hover {
        text-decoration: underline;
      }
    }
  }
}