/* --- Notes --- */


/* --- Start SCSS --- */

#home {
  .statement-module {
    padding-top: 0;
    margin-bottom: 40px;
  }
}



/* --- TABLET STYLES - SM --- */
@media only screen and (min-width: $sm-width) {
  #home {}
}



/* --- TABLET STYLES - MD --- */
@media only screen and (min-width: $md-width) {
  #home {}
}



/* --- DESKTOP STYLES - LG --- */
@media only screen and (min-width: $lg-width) {
  #home {}
}



/* --- LARGE SCREEN STYLES - XL --- */
@media only screen and (min-width: $xl-width) {
  #home {}
}


/* --- LARGE SCREEN STYLES - XXL --- */
@media only screen and (min-width: $xxl-width) {
  #home {}
}