/* --- Notes --- */


/* --- Start SCSS --- */

.image-feature {
  display: grid;
  grid-gap: 40px;

  .features {
    display: grid;
    grid-gap: 20px;

    > ul {
      display: grid;
      grid-gap: 20px;
      padding-bottom: 20px;
      border-bottom: 4px solid $m-grey;

      .teaser-wrapper { 
        ul {
          display: grid;
          grid-gap: 15px;

          li:nth-of-type(1) { // heading
            @include h3;
          }

          // li:nth-of-type(2) { // teaser
          //   @include p;
          // }
        }
      }
    }
  }
}



/* --- TABLET STYLES - SM --- */
@media only screen and (min-width: $sm-width ) {
  .image-feature {
    
  }
}  



/* --- TABLET STYLES - MD --- */
@media only screen and (min-width: $md-width) {
  .image-feature {
    grid-template-columns: 1fr 1fr;

    .features {
      &.count-2 {
        grid-template-columns: 1fr 1fr;
      }

      &.has-link {
        & > ul {
          grid-template-rows: 1fr auto;
        }
      }

      > ul {
        grid-gap: 20px;

        &.has-link {
          grid-template-rows: 1fr auto;
        }

        &:not(.show-all) {
          .teaser-wrapper { 
            position: relative; 
            overflow: hidden;
            @include mask;

            ul {
              position: absolute;
              top: 0;
            }
          }
        }
      }
    }
  }
}  



/* --- DESKTOP STYLES - LG --- */
@media only screen and (min-width: $lg-width) { 
  .image-feature {
   
  }
}  



/* --- LARGE SCREEN STYLES - XL --- */
@media only screen and (min-width: $xl-width) { 
  .image-feature {
  	
  }
}  