/* --- Notes --- */


/* --- Start SCSS --- */

.feature-carousel {
  display: flex;
  flex-direction: column;
  grid-gap: 25px;

  .title {
    @include p-small;
    line-height: 15px;
    font-weight: 700;
  }

  .carousel-wrapper {
    ul {
      display: grid;
      grid-gap: 10px;

      li:nth-of-type(1) { @include h2; }
      li:nth-of-type(2) { @include p; }
    }

    a {
      position: relative;
      z-index:1;
      margin-top: 25px;
    }
  }
}



/* --- TABLET STYLES - SM --- */
@media only screen and (min-width: $sm-width ) {
  .feature-carousel {
    
  }
}  



/* --- TABLET STYLES - MD --- */
@media only screen and (min-width: $md-width) {
  .feature-carousel {
    grid-gap: 0;
    flex-direction: row;

    .title { 
      width: calc(25% - $md-inset);

      span { display: block;}
    }

    .title,
    .carousel-wrapper { float: left; }

    .carousel-wrapper { 
      padding: 0 $md-inset;
      width: calc(75% + $md-inset);

      ul {
        grid-gap: 15px;
      }
    }
  }
}  



/* --- DESKTOP STYLES - LG --- */
@media only screen and (min-width: $lg-width) { 
  .feature-carousel {
    .title { width: calc(25% - $md-inset*2); }

    .carousel-wrapper { 
      padding: 0 $md-inset*2;
      width: calc(75% + $md-inset*2);
    }
  }
}  



/* --- LARGE SCREEN STYLES - XL --- */
@media only screen and (min-width: $xl-width) { 
  .feature-carousel {
  	
  }
}  