/* --- Notes --- */


/* -- Typography -- */
@mixin h1 {
  @include font(34px, 32px, $white);
  font-family: $heading;
  text-transform: uppercase;

  @media only screen and (min-width: $sm-width) {
    @include update-font(53px, 56px)
  }
}

@mixin h2 {
  @include font(21px, 23px, $d-grey);
  font-family: $heading;
  text-transform: uppercase;

  @media only screen and (min-width: $sm-width) {
    @include update-font(28px, 30px)
  }
}

@mixin h3 {
  @include font(15px, 15px, $d-grey);
  font-family: $heading;
  text-transform: uppercase;

  @media only screen and (min-width: $sm-width) {
    @include update-font(20px, 20px)
  }
}

@mixin h4 {
  // @include font(18px,22px,$black,500);

  // @media only screen and (min-width: $sm-width ) {
  //   @include update-font()
  // } 
}

@mixin p {
  @include font(15px, 20px, $d-grey, 400);
}

@mixin p-big {
  @include font(22px, 29px, $d-grey, 400);
}

@mixin p-small {
  @include font(13px, 19px, $d-grey, 400);
}

@mixin p-v-small {
  @include font(10px, 12px, $d-grey, 400);
}