/* --- Notes --- */



/* --- Start SCSS --- */

#join-our-team {
  .featured-listings {
    .intro {
      margin-bottom: 60px;

      .btn {
        margin-top: 30px;
        margin-right: auto;
      }
    }

    .carousel-wrapper {
      ul {
        display: grid;
        grid-template-rows: auto auto 1fr auto;
        height: 100%;

        li:nth-of-type(1) {
          @include p-v-small;
          text-transform: uppercase;
          margin-bottom: 15px;
        }

        li:nth-of-type(2) {
          @include p;
          font-weight: 700;
          text-transform: uppercase;
        }

        li:nth-of-type(3) {
          @include p;
          margin-top: 2px;
        }

        li:nth-of-type(4) {
          padding-top: 30px;
          margin-top: auto;
          padding-bottom: 10px;
          border-bottom: 4px solid $m-grey;
        }
      }
    }
  }

  .bg-vl-grey+.bg-vl-grey {
    margin-top: -20px;
  }

  .brands {
    background-color: $vl-grey;
    padding-top: 30px;
    padding-bottom: 30px;

    .inner {
      max-width: 770px;
      margin: 0 auto;
    }

    .intro {
      @include p-small;
      text-transform: uppercase;
      margin-bottom: 20px;
      font-weight: 700;
    }

    ul {
      display: grid;
      grid-template-rows: repeat(3, 1fr);
      border-top: 1px solid $m-grey;

      li {
        display: inline-flex;
        border-bottom: 1px solid $m-grey;
      }

      img {
        max-height: 25px;
        margin: 15px 0;
      }

      a {
        width: 100%;
      }
    }
  }



  .listings {
    display: grid;
    grid-row-gap: 20px;

    h3 {
      grid-column: 1 / -1;
    }

    .filters {
      display: grid;
      grid-gap: 20px;

      .accordion-item {
        border-color: $m-grey;

        &:before {
          display: none;
        }
      }

      .accordion-title {
        @include font(11px, 12px, $d-grey, 700);
        font-family: $body;
        text-transform: uppercase;
        font-weight: 700;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-right: 20px;

        &:before,
        &:after {
          width: 12px;
          top: 50%;
        }
      }

      .accordion-content {
        .accordion-inner {
          padding-bottom: 15px;
        }

        ul {
          margin-top: -2px;
        }

        ;

        li {
          position: relative;
          @include font(12px, 13px, $d-grey, 400);
          user-select: none;
          cursor: pointer;
          padding: 3px 0 3px 15px;

          &:before {
            content: '';
            position: absolute;
            top: 5px;
            left: 0;
            display: inline-block;
            width: 8px;
            height: 8px;
            border: 1px solid $d-grey;

          }

          &:hover {
            &:before {
              background-color: rgba($d-grey, 0.2);
            }
          }

          &.active {
            &:before {
              background-color: $d-grey;
            }
          }
        }
      }

      .btn {
        margin-left: auto;
      }
    }

    .listings-grid {
      display: grid;
      margin-top: 25px;

      a {
        padding: 15px 60px 15px 0px;
        margin-bottom: -1px;
        border-top: 1px solid $m-grey;
        border-bottom: 1px solid $m-grey;
        position: relative;

        &:hover {
          &:after {
            content: '';
            position: absolute;
            top: 50%;
            right: 18px;
            display: inline-block;
            width: 10px;
            height: 10px;
            border: 1px solid $d-grey;
            border-top: none;
            border-left: none;
            transform: rotate(315deg) translateY(-50%);
          }

          &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: 60px;
            height: 100%;
            @include graident(rgba($l-grey, 0), rgba($l-grey, 0.1), 90deg);
          }
        }
      }

      ul {
        @include p-small;

        li:nth-of-type(1) {
          @include p;
          font-weight: 700;
          text-transform: uppercase;
        }

        li:nth-of-type(2) {
          @include p;
          margin: 2px 0 10px;
        }
      }
    }
  }


}



/* --- TABLET STYLES - SM --- */
@media only screen and (min-width: $sm-width) {
  #join-our-team {
    .brands {

      .intro {
        @include update-font(15px, 20px);
      }

      ul {
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 40px;
        border-top: none;

        li {
          border-bottom: none;
        }

        img {
          max-height: 25px;
          margin-bottom: 0;
        }

        a {
          width: auto;
          display: inline-flex;
        }

        li:nth-of-type(1) a {
          margin-right: auto;
        }

        li:nth-of-type(2) a {
          margin: 0 auto;
        }

        li:nth-of-type(3) a {
          margin-left: auto;
        }
      }
    }
  }
}



/* --- TABLET STYLES - MD --- */
@media only screen and (min-width: $md-width) {
  #join-our-team {
    .featured-listings {
      display: grid;
      grid-template-columns: 40% 60%;
      grid-gap: 0px;

      .intro {
        padding-right: $md-inset;
      }

      .carousel-wrapper {
        margin-top: 5px;
        padding: 0 $md-inset;
        margin-bottom: auto;
      }
    }

    .bg-vl-grey+.bg-vl-grey {
      margin-top: -40px;
    }

    .listings {
      grid-template-columns: 40% 60%;
      grid-row-gap: 30px;

      .filters {
        grid-gap: 30px;
        max-width: 170px;

        .btn {
          margin-left: 0;
          margin-right: auto;
          margin-top: -10px;
        }
      }

      .listings-grid {
        margin-top: 5px;
        margin-bottom: auto;
      }
    }

    .brands {
      padding-top: 45px;
      padding-bottom: 45px;

      ul {
        img {
          max-height: 30px;
        }
      }
    }
  }
}



/* --- DESKTOP STYLES - LG --- */
@media only screen and (min-width: $lg-width) {
  #join-our-team {

    .featured-listings {
      grid-template-columns: 50% 50%;

      .intro {
        padding-right: $md-inset*2;
      }

      .carousel-wrapper {
        padding: 0 $md-inset;
      }
    }
  }
}



/* --- LARGE SCREEN STYLES - XL --- */
@media only screen and (min-width: $xl-width) {
  #join-our-team {}
}


/* --- LARGE SCREEN STYLES - XXL --- */
@media only screen and (min-width: $xxl-width) {
  #join-our-team {}
}