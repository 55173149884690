/* --- Notes --- */

@keyframes graidentGlow {
  0% { opacity: 0.5 }
  50% { opacity: 1 }
  100% { opacity: 0.5 }
}

@keyframes graidentFadeIn {
  0% { opacity: 0 }
  50% { opacity: 1 }
  // 75% { opacity: 0 }
  100% { opacity: 0 }
}


/* --- Start SCSS --- */

.graident {
  @include centerXY;
  width: 100%;
  height: 100%;
  z-index: 0;
  opacity: 0;
  // transition: 1500ms cubic-bezier(0.65, 0, 0.35, 1);
  animation: graidentGlow 5s ease-in-out;
  animation-iteration-count: infinite;

  &.active {
    opacity: 1;
  }


  &.is-anim {
    background: none !important;
    animation: none !important;

    span {
      @include absolute;
      transition: $ease;
      animation: graidentFadeIn 6s ease-in-out;
      animation-iteration-count: infinite;
      opacity: 0;
    }

    span:nth-of-type(1) {
      background: linear-gradient(135deg, transparent 50%, $blue 100%);
      animation-delay: 0s;
    }

    span:nth-of-type(2) {
      background: linear-gradient(225deg, transparent 50%, $purple 100%);
      animation-delay: 2s;
    }

    span:nth-of-type(3) {
      background: linear-gradient(45deg, transparent 50%, $green 100%);
      animation-delay: 4s;
    }
  }
}