/* --- Notes --- */



/* --- Start SCSS --- */

#contact {

  p {
    max-width: 750px;
  }

  .corporate,
  .services {
    display: grid;
    grid-gap: 15px;
  }

  .staff-wrapper,
  .brands-wrapper {
    display: grid;
    grid-gap: 20px;
  }

  .staff-inner,
  .brands-inner {
    display: grid;
    grid-gap: 10px;
    margin-top: 20px;
  }

  .staff-member {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 5px;
    padding-bottom: 12px;
    border-bottom: 1px solid $m-grey;

    span {
      display: block;
      font-weight: 700;
    }

    a {
      @include p-small;
      color: $d-grey;
      text-decoration: underline;
      padding-left: 8px;
      margin-top: auto;

      &:hover {
        color: $l-grey;
      }
    }
  }

  .brand {
    display: grid;
    grid-template-rows: auto 1fr auto;
    grid-gap: 20px;
    border-bottom: 1px solid $m-grey;
    padding: 5px 0 20px;

    li:nth-of-type(1) {
      img {
        max-height: 30px;
        width: auto;
        margin-right: auto;
      }
    }

    li:nth-of-type(2) {
      @include p;
      padding-right: 10px;
    }

    li:nth-of-type(3) {
      a {
        color: $d-grey;
        text-decoration: underline;

        &:hover {
          color: $l-grey;
        }
      }
    }
  }
}






/* --- TABLET STYLES - SM --- */
@media only screen and (min-width: $sm-width) {
  #contact {

    .staff-inner,
    .brands-inner {
      grid-column-gap: 10px;
      grid-row-gap: 25px;
      grid-template-columns: repeat(3, 1fr);
    }

    .staff-member {
      grid-template-columns: 1fr;
      grid-gap: 5px;

      a {
        padding-left: 0;
        margin-right: auto;
      }
    }
  }
}



/* --- TABLET STYLES - MD --- */
@media only screen and (min-width: $md-width) {
  #contact {

    .staff-wrapper,
    .brands-wrapper {
      grid-template-columns: repeat(4, 1fr);
    }

    .staff-inner,
    .brands-inner {
      grid-template-columns: repeat(3, 1fr);
      grid-column: 2/-1;
    }

    .staff-member {
      grid-template-columns: 1fr;
      grid-gap: 5px;
    }

    .brand {
      border-bottom: none;
      padding: 0px;

      li:nth-of-type(1) {
        img {
          max-height: 30px;
          width: auto;
          margin-right: auto;
        }
      }
    }
  }
}



/* --- DESKTOP STYLES - LG --- */
@media only screen and (min-width: $lg-width) {
  #contact {}
}



/* --- LARGE SCREEN STYLES - XL --- */
@media only screen and (min-width: $xl-width) {
  #contact {}
}


/* --- LARGE SCREEN STYLES - XXL --- */
@media only screen and (min-width: $xxl-width) {
  #contact {}
}