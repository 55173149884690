/* --- Notes --- */



/* --- Start SCSS --- */

#fineprint {
  hr {
    border: 0;
    border-top: 1px solid $d-grey;
    margin: 40px 0;
  }

  .big {
    padding-bottom: 30px;
  }

  h2 {
    margin-bottom: 25px;
  }
}



/* --- TABLET STYLES - SM --- */
@media only screen and (min-width: $sm-width) {
  #fineprint {}
}



/* --- TABLET STYLES - MD --- */
@media only screen and (min-width: $md-width) {
  #fineprint {

    hr {
      border: 0;
      border-top: 1px solid $d-grey;
      margin: 60px 0;
    }

    .wysiwyg+h2 {
      margin-top: 60px;
    }
  }
}



/* --- DESKTOP STYLES - LG --- */
@media only screen and (min-width: $lg-width) {
  #fineprint {}
}



/* --- LARGE SCREEN STYLES - XL --- */
@media only screen and (min-width: $xl-width) {
  #fineprint {}
}


/* --- LARGE SCREEN STYLES - XXL --- */
@media only screen and (min-width: $xxl-width) {
  #fineprint {}
}