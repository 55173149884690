/* --- Notes --- */



/* --- Start SCSS --- */

#clinical-excellence {
  .areas-of-excellence {
    display: grid;
    grid-gap: 30px;

    .intro {
      display: grid;
      grid-gap: 20px;

      li:nth-of-type(1) {
        @include h3;
      }

      li:nth-of-type(2) {
        @include p;
      }

      li:nth-of-type(3) {
        @include p-v-small;
        text-transform: uppercase;
        padding-top: 10px;
      }
    }

    .areas {
      display: grid;
      grid-gap: 10px;
      overflow: hidden;

      a {
        overflow: hidden;
        .graident {
          opacity: 1 !important;
        }
      }

      a:hover {
        .graident-wrapper {
          transform: rotate(180deg);
        }

        .btn {
          background-color: rgba($white, 0.33);
        }
      }

      ul {
        display: grid;
        grid-template-rows: 1fr auto;
        position: relative;
        text-transform: uppercase;
        padding: 12px;
        text-align: center;
        height: 100px;

        li:nth-of-type(1) {
          background-color: $d-grey;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: -1;
        }

        li:nth-of-type(2) {
          display: flex;
          @include h3;
          color: $white;

          span {
            display: block;
            margin: auto;
          }
        }

        li:nth-of-type(3) {
          @include update-font(10px, 10px);
          display: inline-block;
          margin: auto;
        }
      }
    }
  }
}



/* --- TABLET STYLES - SM --- */
@media only screen and (min-width: $sm-width) {
  #clinical-excellence {

    .areas-of-excellence {

      .areas {
        grid-template-columns: 1fr 1fr 1fr;

        a:nth-of-type(1) {
          grid-column: 2 / 2;
        }

        .graident-wrapper {
          transition: $slow-ease;
        }

        a:hover {
          .graident-wrapper {
            transform: rotate(180deg) scale(6);
          }
        }

        ul {
          text-transform: uppercase;
          padding: 20px;
          height: 180px;

          li:nth-of-type(3) {
            @include update-font(12px, 12px);
          }
        }
      }
    }
  }
}



/* --- TABLET STYLES - MD --- */
@media only screen and (min-width: $md-width) {
  #clinical-excellence {}
}



/* --- DESKTOP STYLES - LG --- */
@media only screen and (min-width: $lg-width) {
  #clinical-excellence {
    .areas-of-excellence {
      grid-template-columns: 1fr 3fr;
      grid-gap: none;

      .intro {
        padding-right: 20px;
        margin-bottom: auto;

        li:nth-of-type(3) {
          span {
            display: inline-block;
          }
        }
      }
    }
  }
}



/* --- LARGE SCREEN STYLES - XL --- */
@media only screen and (min-width: $xl-width) {
  #clinical-excellence {}
}


/* --- LARGE SCREEN STYLES - XXL --- */
@media only screen and (min-width: $xxl-width) {
  #clinical-excellence {}
}