/* --- Notes --- */


/* --- Start SCSS --- */

#hero {
  background-color: $d-grey;
  padding: 75px 0 40px;

  &.is-news,
  &.is-listing {
    background-color: transparent;
    padding-bottom: 20px;

    & > .container {
      display: grid;
      grid-template-rows: 1fr auto;
      grid-gap: 35px;
    }

    h1,
    p,
    .back {
      color: $d-grey;
      &:before {  
        border-top-color: $d-grey;
        border-left-color: $d-grey;
      }
    }

    h1 { 
      @include h2;
      max-width: 650px;
    }

    .share {
      display: grid;
      grid-template-columns: repeat(4,1fr);
      grid-gap: 8px;
      margin-bottom: auto;
      margin-right: auto;

      span {
        @include font(10px,10px,$d-grey,700);
        text-transform: uppercase;
        grid-column: span 4;
      }

      a {
        &:hover { opacity: 0.75; }

        img {
          width: 27px;
          height: 27px;
        }
      }
    }
  }

  .back {
    position: absolute;
    top: -40px;
    left: $xs-inset;
    @include font(12px,12px,$white,700);
    padding: 10px 0;
    text-transform: uppercase;

    &:before {
      content: '';
      position: absolute;
      top: 10.5px;
      left: -16px;
      border-left: 1px solid $white;
      border-top: 1px solid $white;
      transform: rotate(-45deg);
      width: 10px;
      height: 10px;
    }

    &:hover {
      opacity: 0.7;
    }
  }
  
  h1 {
    color: $white;
    max-width: 900px;

    & + .subheading { padding-top: 25px; }

    span { 
      display: block;
      color: $l-grey; 
    }
  }

  .subheading p {
    @include p-big;
    color: $white;

    & + p { margin-top: 10px; }
  }
}



/* --- TABLET STYLES - SM --- */
@media only screen and (min-width: $sm-width ) {
  #hero {
    padding: 110px 0 100px;

    .back {
      @include update-font(15px,15px);

      &:before {
        content: '';
        left: -20px;
        width: 12px;
        height: 12px;
      }
    }
  }
}  



/* --- TABLET STYLES - MD --- */
@media only screen and (min-width: $md-width) {
  #hero {
    &.is-news,
    &.is-listing {
      padding-bottom: 50px;

      & > .container {
        grid-template-rows: auto;
        grid-template-columns: 1fr auto;
        grid-gap: 60px;
      }

      .share {
        margin: auto 0 0 auto;
        padding-top: 5px;
      }
    }

    .back {
      left: $md-inset;
    }
  }
}  



/* --- DESKTOP STYLES - LG --- */
@media only screen and (min-width: $lg-width) { 
  #hero {

  }
}  



/* --- LARGE SCREEN STYLES - XL --- */
@media only screen and (min-width: $xl-width) { 
  #hero {
  	
  }
}  