/* --- Notes --- */



/* --- Start SCSS --- */

#news-article {
  .hero-image {
    padding: 0;
  }

  .article-wrapper {
    display: grid;
    grid-gap: 20px;

    .detail {
      @include p-v-small;
      margin-bottom: 0;
      text-transform: uppercase;
      line-height: 18px;
    }

    .introduction {
      @include p-big;
      margin-bottom: 10px;
    }

    .back {
      display: inline-block;
      @include p-small;
      text-transform: uppercase;
      font-weight: 700;
      padding: 10px 0;
      margin-top: 40px;
      margin-right: auto;

      &:hover {
        color: $l-grey;
      }
    }
  }
}



/* --- TABLET STYLES - SM --- */
@media only screen and (min-width: $sm-width) {
  #news-article {}
}



/* --- TABLET STYLES - MD --- */
@media only screen and (min-width: $md-width) {
  #news-article {
    .article-wrapper {
      &.no-image {
        margin-top: -40px;
      }
    }
  }
}



/* --- DESKTOP STYLES - LG --- */
@media only screen and (min-width: $lg-width) {
  #news-article {}
}



/* --- LARGE SCREEN STYLES - XL --- */
@media only screen and (min-width: $xl-width) {
  #news-article {}
}


/* --- LARGE SCREEN STYLES - XXL --- */
@media only screen and (min-width: $xxl-width) {
  #news-article {}
}