/* --- Notes --- */



/* --- Start SCSS --- */

.video-wrapper {
  position: relative;
  background-color: $black;

  .trigger {
    @include centerXY;
    z-index: 2;
    width: 65px;
    height: 65px;
    border: 1px solid $white;
    border-radius: 50%;
    pointer-events: none;

    img,
    svg {
      @include centerXY;
      width: 30px;
      stroke: $white;
    }

    // play triangle
    &:before {
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 12.5px 0 12.5px 22px;
      border-color: transparent transparent transparent $white;
      @include centerXY;
      margin-left: 3px;
    }
  }

  .thumb {
    z-index: 1;
    cursor: pointer;
    opacity: 0.8;
    transition: opacity $ease;

    @media (hover) {
      &:hover {
        opacity: 0.6;
      }
    }
  }
}



/* --- TABLET STYLES - SM --- */
@media only screen and (min-width: $sm-width) {
  .video-wrapper {}
}



/* --- TABLET STYLES - MD --- */
@media only screen and (min-width: $md-width) {
  .video-wrapper {}
}



/* --- DESKTOP STYLES - LG --- */
@media only screen and (min-width: $lg-width) {
  .video-wrapper {}
}



/* --- LARGE SCREEN STYLES - XL --- */
@media only screen and (min-width: $xl-width) {
  .video-wrapper {}
}