/* --- Notes --- */



/* --- Start SCSS --- */


.accordion-item {
  position: relative;
  overflow: hidden;

  &:last-of-type {
    border-bottom: 1px solid $m-grey;
  }

  &:hover {
    &:before {
      content: '';
      position: absolute;
      top: -70px;
      width: 100%;
      height: 140px;
      -webkit-mask-image: linear-gradient(to bottom, black 0%, transparent 100%);
      background: radial-gradient(circle, rgba($l-grey, 0.6) 0%, rgba($l-grey, 0) 100%);
      // background: radial-gradient(circle, hotpink 0%, yellow 100%);
    }
  }

  .accordion-title {
    user-select: none;
    cursor: pointer;
    position: relative;
    padding: 25px 35px 25px 0;
    border-top: 1px solid $m-grey;
    @include h3;

    span {
      display: block;
      margin-top: 10px;
      @include p;
      font-family: $body;
      max-width: 750px;
      text-transform: none;
    }

    &:before,
    &:after {
      content: '';
      width: 14px;
      height: 1px;
      display: block;
      background-color: $d-grey;
      position: absolute;
      top: 32px;
      right: 0;
    }

    &:before {
      transform: rotate(90deg);
    }

    &.active {
      &:before {
        display: none;
      }
    }

    &:hover {
      // color: $l-grey;

      &:before,
      &:after {
        background-color: rgba($d-grey, 0.5);
      }
    }
  }

  .accordion-content {
    width: 100%;
    position: relative;
    height: 0;
    opacity: 0;
    transition: all 400ms;
    overflow: hidden;

    &.active {
      opacity: 1;
    }

    .accordion-inner {
      padding-bottom: 60px;
    }
  }
}



/* --- TABLET STYLES - SM --- */
@media only screen and (min-width: $sm-width) {
  .accordion-item {}
}



/* --- TABLET STYLES - MD --- */
@media only screen and (min-width: $md-width) {
  .accordion-item {
    &:hover {
      &:before {
        height: 200px;
        top: -100px;
      }
    }

    .accordion-title {
      padding: 40px 50px 40px 0;

      &:before,
      &:after {
        width: 20px;
        top: 50px;
      }
    }
  }
}



/* --- DESKTOP STYLES - LG --- */
@media only screen and (min-width: $lg-width) {
  .accordion-item {}
}



/* --- LARGE SCREEN STYLES - XL --- */
@media only screen and (min-width: $xl-width) {
  .accordion-item {}
}