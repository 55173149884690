
/* --- Notes --- */



/* --- Start SCSS --- */

html {
  box-sizing: border-box;
}

*, 
*::before, 
*::after {
  box-sizing: inherit;
}

main {
	scroll-behavior: smooth;
	background-color: $white;
	max-width: 100%;
	margin: 0 auto;
	padding-top: $xs-menu;
}

.content{ 
	// overflow-x: hidden;
}

.fluid-container { width: 100%; }

.container {
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	max-width: $max-width; 
	width: calc(100% - #{$xs-padding * 2}); 
}

.inset,
.inset-1,
.inset-2 { 
	padding-left: $xs-inset; 
	padding-right: $xs-inset;
}




/* --- TABLET STYLES - SM --- */
@media only screen and (min-width: $sm-width) {

	main { padding-top: $sm-menu; }

}  


/* --- TABLET STYLES - MD --- */
@media only screen and (min-width: $md-width) {

	main { padding-top: $md-menu; }
	
	.container {
		width: calc(100% - #{$md-padding * 2});
	}

	.inset,
	.inset-1 {
		padding-left: $md-inset;
		padding-right: $md-inset;

		.inset {
			padding-left: $md-inset*1;
			padding-right: $md-inset*1;
		}
	}

	.inset-2 { 
		padding-left: $md-inset*2; 
		padding-right: $md-inset*2;
	}
}  


/* --- DESKTOP STYLES - LG --- */
@media only screen and (min-width: $lg-width) { 

	main { padding-top: $lg-menu; }

	.inset,
	.inset-1 {
		.inset {
			padding-left: $md-inset*2;
			padding-right: $md-inset*2;
		}
	}

	.inset-2 { 
		padding-left: $md-inset*3; 
		padding-right: $md-inset*3;
	}
}  


/* --- LARGE SCREEN STYLES - XL --- */
@media only screen and (min-width: $xl-width) { 

}  