/* --- Notes --- */

// just some basic styles to build upon

$pagination-default: rgba(hotpink, 0.8);
$pagination-hover: hotpink;
$pagination-active: black;
$pagination-size: 8px;
$pagination-padding: 6px;

$next-prev-default: $d-grey;
$next-prev-hover: $l-grey;
$next-prev-disabled: $vl-grey;


/* --- Start SCSS --- */




.carousel-wrapper {
  position: relative;

  .slide {
    height: auto;
  }

  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    span {
      display: inline-block;
      padding: $pagination-padding;
      cursor: pointer;

      &:after {
        content: '';
        @include circle($pagination-size, $pagination-default);
        display: block;
      }

      @media (hover) {
        &:hover:after {
          background-color: $pagination-hover;
        }
      }

      &.active:after {
        background-color: $pagination-active !important;
      }
    }
  }

  .prev,
  .next {
    position: absolute;
    top: 50%;
    left: auto;
    width: 90px;
    height: 100%;
    outline: none;
    margin: 0;
    cursor: pointer;

    &:after {
      content: '';
      @include centerY;
      width: 11px;
      height: 11px;
      border-left: 1px solid $next-prev-default;
      border-bottom: 1px solid $next-prev-default;
    }

    @media (hover) {
      &:hover {
        &:after {
          border-color: $next-prev-hover;
        }
      }
    }

    &.disabled:after {
      border-color: $next-prev-disabled;
    }
  }

  .prev {
    left: -45px;
    transform: translateY(-50%);

    &:after {
      left: 18px;
      transform: translateY(-50%) rotate(45deg)
    }
  }

  .next {
    right: -45px;
    transform: translateY(-50%);

    &:after {
      right: 18px;
      transform: translateY(-50%) rotate(-135deg)
    }
  }
}



/* --- TABLET STYLES - SM --- */
@media only screen and (min-width: $sm-width) {

}



/* --- TABLET STYLES - MD --- */
@media only screen and (min-width: $md-width) {
  .carousel-wrapper {

    .prev,
    .next {
      // width: 20px;
      // height: 40px;

      &:after {
        width: 21px;
        height: 21px;
      }
    }

    .prev {
      left: 0px;

      &:after {
        left: 5px;
      }
    }

    .next {
      right: 0px;

      &:after {
        right: 5px;
      }
    }
  }
}



/* --- DESKTOP STYLES - LG --- */
@media only screen and (min-width: $lg-width) {
  .carousel-wrapper {}
}



/* --- LARGE SCREEN STYLES - XL --- */
@media only screen and (min-width: $xl-width) {
  .carousel-wrapper {}
}