/* --- Notes --- */



/* --- Start SCSS --- */

#about {

  .introduction {
    display: grid;
    grid-row-gap: 50px;
    grid-column-gap: 10px;

    .col:nth-of-type(1) {
      display: grid;
      grid-gap: 20px;
      margin-bottom: auto;
    }

    .col:nth-of-type(2) {
      display: grid;

      svg {
        grid-column: 1 / -1;
        max-width: 185px;
        margin: auto auto 0 auto;
      }
    }

    .col:nth-of-type(3) {
      grid-column: 1 / -1;
      display: grid;
      grid-template-columns: repeat(3, auto);
      justify-content: space-between;
      margin-top: -20px;

      a {
        margin: auto;
        padding-bottom: 5px;

        &:nth-of-type(1) {
          border-bottom: 2px solid $blue;
        }

        &:nth-of-type(2) {
          border-bottom: 2px solid $green;
        }

        &:nth-of-type(3) {
          border-bottom: 2px solid $purple;
        }

        &:hover {
          &:nth-of-type(1) {
            border-bottom: 2px solid rgba($blue, 0.5);
          }

          &:nth-of-type(2) {
            border-bottom: 2px solid rgba($green, 0.5);
          }

          &:nth-of-type(3) {
            border-bottom: 2px solid rgba($purple, 0.5);
          }
        }
      }

      img {
        height: 15px;
        width: auto;
        margin: auto;
      }
    }
  }

  .our-teams {
    .accordion-inner {
      display: grid;
      grid-row-gap: 40px;
      grid-column-gap: 10px;
    }

    .staff-member {
      display: grid;
      grid-gap: 5px;
      @include p;
      margin-bottom: auto;

      .img-wrapper {
        margin-bottom: 10px;
      }

      .name,
      .title {
        text-transform: uppercase;
        font-weight: 700;
      }

      .title {
        margin-top: -5px;
      }

      .email a {
        color: $d-grey;
        text-decoration: underline;
        padding-bottom: 4px;
        display: inline-block;

        &:hover {
          color: $l-grey;
        }
      }

      .description {
        margin-right: 10px;
      }
    }

    .statement-module {
      grid-column: 1 / -1;
      padding-bottom: 0;
      padding-right: 0;
      padding-left: 0;
    }
  }

  .structure {
    display: grid;
    grid-gap: 15px;

    h3,
    p,
    a {
      color: $white;
      max-width: 750px;
    }

    a:hover {
      color: $l-grey;
    }

    svg {
      max-width: 750px;
      margin: 15px auto 0;
    }
  }

  .our-radiologists {

    h3 {
      margin-bottom: 15px;
    }

    h3,
    p {
      max-width: 750px;
    }

    .teaser-carousel {
      margin-top: 80px;
    }
  }
}



/* --- TABLET STYLES - SM --- */
@media only screen and (min-width: $sm-width) {
  #about {
    .introduction {
      grid-template-columns: repeat(2, 1fr);

      .col:nth-of-type(2) {
        svg {
          max-width: 270px;
        }
      }

      .col:nth-of-type(3) {
        margin-top: 0;

        a {
          padding-bottom: 10px;
          border-width: 4px;
        }

        img {
          height: 25px;
        }
      }
    }

    .our-teams {
      .accordion-inner {
        grid-template-columns: repeat(2, 1fr);

        .img-wrapper {
          padding-top: 100%;
        }
      }
    }
  }
}




/* --- TABLET STYLES - MD --- */
@media only screen and (min-width: $md-width) {
  #about {
    .our-teams {
      .accordion-inner {
        grid-template-columns: repeat(3, 1fr);
      }
    }

    .structure {
      svg {
        margin-top: 30px;
      }
    }

    .our-radiologists {
      .teaser-carousel {
        margin-top: 80px;
      }
    }
  }
}



/* --- DESKTOP STYLES - LG --- */
@media only screen and (min-width: $lg-width) {
  #about {
    .introduction {
      .col:nth-of-type(1) {
        margin: auto 0;
      }

      .col:nth-of-type(3) {
        margin-top: 0;
        grid-column: 2/-1;
        grid-gap: 25px;
      }
    }

    .our-teams {
      .accordion-inner {
        grid-template-columns: repeat(4, 1fr);
      }
    }
  }
}



/* --- LARGE SCREEN STYLES - XL --- */
@media only screen and (min-width: $xl-width) {
  #about {}
}


/* --- LARGE SCREEN STYLES - XXL --- */
@media only screen and (min-width: $xxl-width) {
  #about {}
}