/* --- Notes --- */


/* --- Start SCSS --- */

.teaser-carousel {

  &.white-bg .inner {
    background-color: $white;
  }

  &.no-padding {
    .inner {
      padding: 0;

      &>ul {
        padding: 0 0 12px 0
      }
    }
  }

  .inner {
    display: grid;
    grid-template-rows: auto 1fr;
    background-color: $vl-grey;

    &.no-image {
      grid-template-rows: 1fr;
      grid-template-columns: 1fr;

      .teaser-wrapper {
        -webkit-mask-image: none;
        mask-image: none;

        ul {
           position: static;
        }
      }
    }

    >ul {
      display: grid;
      grid-template-rows: auto 1fr auto;
      grid-gap: 15px;
      padding: 12px;

      li:nth-of-type(1) {
        // title
        @include p-v-small;
        text-transform: uppercase;
      }
    }

    .teaser-wrapper {
      position: relative;
      overflow: hidden;
      // @include mask;

      ul {
        display: grid;
        grid-template-rows: auto 1fr;
        grid-gap: 5px;
        height: 100%;

        li:nth-of-type(1) {
          // heading
          @include p;
          font-weight: 700;
          text-transform: uppercase;
        }

        li:nth-of-type(2) {
          // teaser
          @include p;
        }
      }
    }
  }
}



/* --- TABLET STYLES - SM --- */
@media only screen and (min-width: $sm-width) {
  .teaser-carousel {

    &.no-padding {
      .inner>ul {
        padding: 0 12px 1px 0;
      }
    }

    .inner {
      grid-template-columns: 1fr 2fr;
      grid-template-rows: auto;
      grid-gap: 12px;
      padding: 12px;

      >ul {
        padding: 0;
      }
    }

    .teaser-wrapper {
      @include mask;

      ul {
        position: absolute;
        top: 0;
      }
    }
  }
}



/* --- TABLET STYLES - MD --- */
@media only screen and (min-width: $md-width) {
  .teaser-carousel {
    .inner {
      >ul {
        grid-gap: 30px;
      }
    }
  }
}



/* --- DESKTOP STYLES - LG --- */
@media only screen and (min-width: $lg-width) {
  .teaser-carousel {}
}



/* --- LARGE SCREEN STYLES - XL --- */
@media only screen and (min-width: $xl-width) {
  .teaser-carousel {}
}