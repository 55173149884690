/* --- Notes --- */


/* --- Variables --- */

$white: rgb(255, 255, 255);
$grey-1: rgb(221, 221, 221);
$grey-2: rgb(213, 213, 213);
$grey-3: rgb(202, 202, 202);
$grey-4: rgb(189, 189, 189);
$grey-5: rgb(172, 172, 172);
$grey-6: rgb(151, 151, 151);
$grey-7: rgb(125, 125, 125);
$grey-8: rgb(92, 92, 92);
$grey-9: rgb(51, 51, 51);
$black: rgb(0, 0, 0);

$vl-grey : rgb(245, 245, 245);
$l-grey : rgb(150, 150, 150);
$m-grey : rgb(88, 89, 91);
$d-grey : #3c3c3c;

$blue : rgb(0, 156, 222);
$green : rgb(164, 199, 76);
$purple : rgb(97, 85, 154);

$heading : 'Ardela Edge';
$body : 'DM Sans';

$ease: 350ms cubic-bezier(0.65, 0, 0.35, 1);
$slow-ease: 500ms cubic-bezier(0.65, 0, 0.35, 1);
$v-slow-ease: 1000ms cubic-bezier(0.65, 0, 0.35, 1);


/* --- Scaffolding -- */

$xs-width: 100%;
$xs-padding: 25px;
$xs-menu : 50px;
$xs-inset: 20px;

$sm-width: 576px;
$sm-padding: $xs-padding;
$sm-menu : $xs-menu;
$sm-inset: $xs-inset;

$md-width: 768px;
$md-menu : 65px;
$md-padding: 35px;
$md-inset: 45px;

$lg-width: 992px;
$lg-menu : 80px;
$lg-padding: $sm-padding;
$lg-inset: $sm-inset;

$xl-width: 1200px;
$xl-padding: $sm-padding;
$xl-inset: $sm-inset;

$xxl-width: 1440px;
$xxl-padding: $sm-padding;
$xxl-inset: $sm-inset;

$max-width: $lg-width;