
/* --- Notes --- */

// General shared styles go here




/* --- Start SCSS --- */

.btn {
  display: inline-block;
  text-transform: uppercase;
  padding: 7px 26px;
  border: 1px solid $d-grey;
  border-radius: 14px;
  @include font(12px,12px,$d-grey,700);
  cursor: pointer;

  &:hover {
    background-color: $d-grey;
    color: $white;
  }

  &.btn-white {
    color: $white;
    border: 1px solid $white;

    &:hover {
      background-color: rgba($white,0.33);
    }
  }
}




/* --- TABLET STYLES - SM --- */
@media only screen and (min-width: $sm-width ) {
	
}  



/* --- TABLET STYLES - MD --- */
@media only screen and (min-width: $md-width) {
	
}  



/* --- DESKTOP STYLES - LG --- */
@media only screen and (min-width: $lg-width) { 
	
}  



/* --- LARGE SCREEN STYLES - XL --- */
@media only screen and (min-width: $xl-width) { 
	
}  