/* --- Notes --- */



/* --- Start SCSS --- */

footer {
  padding: 20px 0;
  background-color: $vl-grey;
  border-bottom: 8px solid $d-grey;  
  padding: 60px 0 15px;

  & > ul {
    display: grid;

    & > li:nth-of-type(1) { // logo
      max-width: 115px;
      cursor: pointer;
    }

    & > li:nth-of-type(2) { // byline
      @include font(20px,20px,$m-grey);
      font-family: $heading;
      border-top: 1px solid $d-grey;
      margin-top: 20px;
      padding: 10px 0 60px;
    }

    & > li:nth-of-type(3) { // subsidiaries 
      @include font(10px,12px,$d-grey,700);
      margin-bottom: 30px;

      ul {
        li:nth-of-type(1) { // title
          padding-bottom: 8px;
          border-bottom: 1px solid $d-grey;
        }

        a { // logo
          display: block;
          padding: 15px 0;
          border-bottom: 1px solid $d-grey;

          img { 
            height: 25px;
            width: auto;
            margin-right: auto;
          }
        }
      }      
    }

    & > li:nth-of-type(4) { // social
      ul {
        display: grid;
        grid-gap: 8px;
        margin-bottom: 30px;

        &.col-1 {
          grid-template-columns: 27px;
        }

        &.col-2 {
          grid-template-columns: 27px 27px;
        }

        a {
          :hover {
            opacity: 0.75;
          }
        }
      }
    }

    & > li:nth-of-type(5) { // fineprint
      ul {
        display: grid;
        grid-template-columns: 1fr auto;
        @include p-v-small;
        color: $m-grey;

        a {
          color: $m-grey;
          text-decoration: underline;

          &:hover {
            color: $d-grey;
          }
        }
      }
    }
  }
}



/* --- TABLET STYLES - SM --- */
@media only screen and (min-width: $sm-width ) {
  footer {
    
  }
}  



/* --- TABLET STYLES - MD --- */
@media only screen and (min-width: $md-width) {
  footer {
    padding: 60px 0 35px;

    & > ul {
      grid-template-columns: 1fr auto;

      & > li:nth-of-type(1) { // logo
        grid-column: span 2;
        max-width: 140px;
      }

      & > li:nth-of-type(2) { // byline
        border-top: 1px solid $d-grey;
        margin-top: 20px;
        padding: 20px 0 40px;
      }

      & > li:nth-of-type(3) { // subsidiaries 
        margin-bottom: 0px;

        ul {
          display: grid;
          grid-template-columns: auto auto auto;
          grid-column-gap: 30px;
          margin-right: auto;
          width: fit-content;

          li:nth-of-type(1) { // title
            padding-bottom: 12px;
            border-bottom: none;
            grid-column: span 3;
          }

          a { // logo
            padding: 0;
            border-bottom: none;
            margin: auto 0;
          }
        }      
      }

      & > li:nth-of-type(4) { // social
        grid-column: 2;
        grid-row: 2;
        border-top: 1px solid $d-grey;
        margin-top: 20px;
        padding: 20px 0 40px;

        ul {
          float: right;
          margin: 0;
        }
      }

      & > li:nth-of-type(5) { // fineprint
        margin-top: auto;

        ul {
          // grid-template-columns: auto auto;
          // grid-gap: 15px;
          grid-template-rows: 1fr 1fr;
          grid-template-columns: 1fr;
          grid-gap: 4px;
          margin-left: 60px;
          text-align: right;
        }
      }
    }
  }
}  



/* --- DESKTOP STYLES - LG --- */
@media only screen and (min-width: $lg-width) { 
  footer {
    & > ul {
      & > li:nth-of-type(5) { // fineprint
        ul {
          grid-template-columns: auto auto;
          grid-gap: 15px;
          grid-template-rows: 1fr;
        }
      }
    }
  }
}  



/* --- LARGE SCREEN STYLES - XL --- */
@media only screen and (min-width: $xl-width) { 
  footer {

  }
}  