/* --- Notes --- */



/* --- Start SCSS --- */

#news {
  .articles {
    display: grid;
    grid-gap: 50px;

    .article-grid {
      display: grid;
      grid-gap: 50px;
    }

    .article-filters {
      display: grid;
      grid-gap: 5px;
      margin-bottom: auto;

      li {
        position: relative;
        @include p-v-small;
        text-transform: uppercase;
        font-weight: 700;
        cursor: pointer;
        user-select: none;
        padding: 8px 8px 8px 33px;
        margin-right: auto;
        border: 1px solid $m-grey;
        border-radius: 14px;
        color: $m-grey;

        &:before {
          @include absolute;
          content: '';
          width: 20px;
          height: 20px;
          border: 1px solid $m-grey;
          border-radius: 50%;
          left: 5px;
          top: 50%;
          transform: translateY(-50%);
        }

        &:hover {
          &:before {
            background-color: $l-grey;
          }
        }

        &.active {
          color: $white;
          background-color: $m-grey;

          &:before {
            background-color: $l-grey;
            border-color: $white;
          }
        }
      }
    }

    .article-card {
      position: relative;
      border-bottom: 1px solid $m-grey;
      padding-bottom: 20px;

      &.no-image {
        background-color: $m-grey;
        color: $white !important;
        padding: 12px;

        .category,
        .heading,
        .teaser { color: $white; }
      }

      &:hover {
        border-bottom: 1px solid rgba($l-grey,0.8);

        li:nth-of-type(1) {
          opacity: 1;
          pointer-events: auto;
        }
      }
      
      .overlay {
        @include absolute;
        display: flex;
        background-color: rgba($l-grey,0.8);
        align-items: center;
        justify-content: center;
        z-index: 1;
        opacity: 0;
        pointer-events: none;

        span { 
          pointer-events: none;
        }
      }

      .img-wrapper {
        margin-bottom: 8px;
      }

      .category {
        @include p-v-small;
        text-transform: uppercase;
        margin-bottom: 10px;
      }

      .heading {
        @include p;
        font-weight: 700;
        text-transform: uppercase;
      }

      .teaser {
        @include p;
        margin-top: 2px;
      }
    }

    .load-more {
      grid-column: 1 / -1;
      margin: 0 auto;
      cursor: pointer;
      margin-top: 60px;
      user-select: none;

      &.disabled {
        cursor: wait;
      }
    }
  }
}



/* --- TABLET STYLES - SM --- */
@media only screen and (min-width: $sm-width ) {
  #news {
    .articles {

      .article-grid {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px;
      }

      .article-card {
        &.no-image {
          ul {
            display: grid;
            grid-template-rows: auto auto 1fr;
            height: 100%;
          }
        }

        .teaser {
          position: relative;
          @include mask;

          & > div {
            @include absolute;
          }
        }
      }
    }
  }
}  



/* --- TABLET STYLES - MD --- */
@media only screen and (min-width: $md-width) {
  #news {
     .articles {
      .article-grid {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }
}  



/* --- DESKTOP STYLES - LG --- */
@media only screen and (min-width: $lg-width) { 
  #news {
    .articles {
      grid-template-columns: 1fr 3fr;
      grid-gap: 0;

      .article-filters {
        padding-right: 50px;
      }

      .article-grid {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }
}  



/* --- LARGE SCREEN STYLES - XL --- */
@media only screen and (min-width: $xl-width) { 
  #news {
    
  }
}  


/* --- LARGE SCREEN STYLES - XXL --- */
@media only screen and (min-width: $xxl-width) { 
  #news {
    
  }
}  