/* --- Notes --- */


/* --- Start SCSS --- */

.statement-module {
  padding-top: 20px;
  padding-bottom: 20px;

  &.has-bg { 
    background-color: $vl-grey; 
  }

  &.border-top { border-top: 1px solid $d-grey; }
  &.border-bottom { border-bottom: 1px solid $d-grey; }
  
  .v-small { 
    margin-top: 30px;
    text-transform: uppercase; 
  }

  .big,
  .v-small {
    max-width: 750px;
    // margin: 0 auto;
  }

  a {
    margin-top: 30px;
  }
}



/* --- TABLET STYLES - SM --- */
@media only screen and (min-width: $sm-width ) {
  .statement-module {
    
  }
}  



/* --- TABLET STYLES - MD --- */
@media only screen and (min-width: $md-width) {
  .statement-module {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}  



/* --- DESKTOP STYLES - LG --- */
@media only screen and (min-width: $lg-width) { 
  .statement-module {

  }
}  



/* --- LARGE SCREEN STYLES - XL --- */
@media only screen and (min-width: $xl-width) { 
  .statement-module {
  	
  }
}  