
/* --- Notes --- */


/* --- Helper --- */

@function tint($color, $percentage) {
  @return mix(white, $color, $percentage);
}

@function shade($color, $percentage) {
  @return mix(black, $color, $percentage);
}

@mixin circle ($size,$color) {
  border-radius: 50%;
  width: $size;
  height: $size; 
  background-color: $color;
  display: inline-block;
}

@mixin absolute {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@mixin centerXY($transform:null) {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) $transform;
}

@mixin centerX($transform:null) {
  position: absolute;
  left: 50%;
  transform: translateX(-50%) $transform;
}

@mixin centerY($transform:null) {
  position: absolute;
  top: 50%;
  transform: translateY(-50%) $transform;
}

@mixin mask($height:50px) {
  -webkit-mask-image: linear-gradient(to bottom, black calc(100% - $height), transparent calc(100%));
  mask-image: linear-gradient(to bottom, black calc(100% - $height), transparent calc(100%));
}

@mixin graident($x,$y,$deg) {
  background-image: linear-gradient($deg, $x 0%, $y 100%);
}

@mixin font($font-size:"", $line-height:"", $color:"", $font-weight:"") {
  @if $font-size != "" { font-size: $font-size; }
  @if $line-height != "" { line-height: $line-height; }
  @if $color != "" { color: $color; }
  @if $font-weight != "" { 
    font-weight: $font-weight; 
  }
}

@mixin update-font($font-size:"", $line-height:"",$important:false) {
  @if $important == true {
    @if $font-size != "" { font-size: $font-size !important; }
    @if $line-height != "" { line-height: $line-height !important; }
  } @else {
    @if $font-size != "" { font-size: $font-size; }
    @if $line-height != "" { line-height: $line-height; }
  }
}

// @include placeholder { color: $grey; }
@mixin placeholder {
  &.placeholder { @content; }
  &:-moz-placeholder { @content; }
  &::-moz-placeholder { @content; }
  &:-ms-input-placeholder { @content; }
  &::-webkit-input-placeholder { @content; }
}


// @include ellipsis(200px);
@mixin ellipsis($max-width) {
  max-width: $max-width;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


@mixin gpu() {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000;
  -moz-perspective: 1000;
  -ms-perspective: 1000;
  perspective: 1000;
}