/* --- Notes --- */

// menu height set in _variables
$menu-bg : $white;
$nav-bg : $white;
$nav-item : $d-grey;
$nav-item-hover : $l-grey;
$nav-item-active : $d-grey;



/* --- Start SCSS --- */

header {
  background-color: $menu-bg;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: $xs-menu;
  transition: all $ease;

  >.container {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    grid-gap: 60px;
    transition: all $ease;
  }

  .disabled {
    pointer-events: none;
  }

  .active:not(.logo) {
    color: $nav-item-active;
  }

  .logo {
    width: 75px;
    margin-top: 5px;
    display: block;
    transition: all $ease;
  }

  .logo,
  .hamburger {
    z-index: 200;
  }

  .hamburger {
    width: 30px;
    cursor: pointer;
    pointer-events: auto;
    position: relative;
    display: block;
    align-self: stretch;
    margin-left: auto;

    &:before,
    &:after {
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      height: 1.5px;
      background-color: $nav-item;
      transition: transform $ease, top $ease;
    }

    &:before {
      top: calc(50% - 4px)
    }

    &:after {
      top: calc(50% + 4px)
    }

    @media (hover) {
      &:hover {

        &:before,
        &:after {
          background-color: $nav-item-hover
        }
      }
    }

    &.active {

      &:before,
      &:after {
        top: 50%;
      }

      &:before {
        transform: rotate(45deg)
      }

      &:after {
        transform: rotate(-45deg)
      }
    }
  }


  nav {
    &.hamburger-mode {
      opacity: 0;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 150;
      background-color: $nav-bg;
      pointer-events: none;
      padding: #{$xs-menu + $xs-padding} $xs-padding $xs-padding;
      box-shadow: 0px 0px 30px 0px rgba($black, .75);
      height: calc(var(--vh, 1vh) * 100);
      display: grid;
      grid-template-rows: 1fr auto;
      grid-gap: $xs-padding;

      &.active {
        opacity: 1;
        pointer-events: auto;
      }

      &.transition {
        transition: opacity $ease;
      }

      .menu-items {
        border-top: 1px solid $l-grey;
        @include font(20px, 20px, $d-grey, 400);
        font-family: $heading;
        margin: auto 0;

        a {
          padding: 15px 0;
          display: block;
          border-bottom: 1px solid $l-grey;
          color: $d-grey;

          &.exact-active {
            border-right: 6px solid $m-grey;
          }

          &:hover {
            @include graident(transparent, $vl-grey, 90deg);
          }
        }
      }

      .icon-bar {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        margin-right: auto;
        width: fit-content;
        margin-left: -8px;

        img {
          height: 24px;
          width: auto;
          padding: 0 8px;
        }
      }
    }

    &:not(.hamburger-mode) {
      display: grid;
      grid-template-rows: auto auto;
      width: 100%;
      max-width: 700px;
      grid-gap: 18px;
      margin-left: auto;
      margin-top: -5px;

      .menu-items {
        display: flex;
        width: 100%;
        justify-content: space-between;
        @include font(15px, 15px, $d-grey, 700);
        order: 2;
        margin-left: auto;

        li {
          display: inline-block;
          text-transform: uppercase;
        }

        a {
          display: inline-block;
          position: relative;
          color: $nav-item;

          &.active {
            &:after {
              content: '';
              position: absolute;
              bottom: -4px;
              left: 0;
              display: block;
              width: 100%;
              height: 1px;
              background-color: $nav-item-active;
            }
          }

          &:hover {
            color: $nav-item-hover;
          }
        }
      }

      .icon-bar {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        width: fit-content;
        margin-left: auto;
        margin-right: -5px;
        order: 1;

        img {
          height: 18px;
          width: auto;
          padding: 0 5px;
        }
      }
    }
  }
}



/* --- TABLET STYLES - SM --- */
@media only screen and (min-width: $sm-width) {
  header {
    height: $sm-menu;

    nav {
      &.hamburger-mode {
        padding: #{$sm-menu + $sm-padding} $sm-padding $sm-padding;
        grid-gap: $sm-padding;
      }
    }
  }
}



/* --- TABLET STYLES - MD --- */
@media only screen and (min-width: $md-width) {
  header {
    height: $md-menu;

    .logo {
      width: 115px;
    }

    nav {
      &.hamburger-mode {
        padding: #{$md-menu + $md-padding} $md-padding $md-padding;
        grid-gap: $md-padding;
      }
    }
  }
}



/* --- DESKTOP STYLES - LG --- */
@media only screen and (min-width: $lg-width) {
  header {
    height: $lg-menu;
  }
}



/* --- LARGE SCREEN STYLES - XL --- */
@media only screen and (min-width: $xl-width) {
  header {}
}