/* --- Notes --- */


/* --- Start SCSS --- */

.smart-container {
  position: relative;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 40px;

  &.bg-vl-grey { background-color: $vl-grey; }
  &.bg-l-grey { background-color: $l-grey; }
  &.bg-m-grey { background-color: $m-grey; }
  &.bg-d-grey { background-color: $d-grey; }

  > .graident {
    @include centerXY;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  > .container {
    position: relative;
    z-index: 1;
  }
}



/* --- TABLET STYLES - SM --- */
@media only screen and (min-width: $sm-width ) {
  .smart-container {
   
  }
}  



/* --- TABLET STYLES - MD --- */
@media only screen and (min-width: $md-width) {
  .smart-container {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}  



/* --- DESKTOP STYLES - LG --- */
@media only screen and (min-width: $lg-width) { 
  .smart-container {

  }
}  



/* --- LARGE SCREEN STYLES - XL --- */
@media only screen and (min-width: $xl-width) { 
  .smart-container {
  	
  }
}  