/* --- Notes --- */



/* --- Start SCSS --- */

#clinical-excellence-item {

  h2 {
    padding-bottom: 30px;
  }


  .director {
    display: grid;
    grid-gap: 30px;

    .name {
      grid-column: 1 / -1;
      @include p;
      margin-bottom: -15px;

      span {
        text-transform: uppercase;
        display: block;
      }
    }

    .statement-module {
      padding-bottom: 0;

      .big,
      .v-small {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  .content-wrapper {
    display: grid;
    grid-gap: 30px;

    .big {
      color: $m-grey;
      padding: 0 0 30px 0;
    }

    .small {
      padding: 0 0 30px 0;
    }
  }

  .accordion-inner {
    display: grid;
    grid-gap: 25px;

    .wysiwyg {
      padding-bottom: 10px;
    }

    .assets-wrapper {
      display: grid;
      grid-gap: 25px;
    }
  }
}



/* --- TABLET STYLES - SM --- */
@media only screen and (min-width: $sm-width) {
  #clinical-excellence-item {
    .director {
      grid-gap: 30px;
      grid-template-columns: 1fr 2fr;

      .wysiwyg {
        margin-bottom: auto;
      }

      .statement-module {
        grid-column: 1 / -1;
      }
    }
  }
}



/* --- TABLET STYLES - MD --- */
@media only screen and (min-width: $md-width) {
  #clinical-excellence-item {

    .director {
      grid-gap: 50px;

      .name {
        margin-bottom: -35px;
      }
    }



    .content-wrapper {
      .img-wrapper {
        margin-bottom: 20px;
      }
    }

    .accordion-inner {
      grid-template-columns: 2fr 1fr;
      grid-row-gap: 40px;

      .wysiwyg {
        margin-bottom: auto;
      }

      .assets-wrapper {
        grid-gap: 10px;
        margin-bottom: auto;
      }

      .statement-module {
        grid-column: 1 / -1;
      }
    }
  }
}



/* --- DESKTOP STYLES - LG --- */
@media only screen and (min-width: $lg-width) {
  #clinical-excellence-item {}
}



/* --- LARGE SCREEN STYLES - XL --- */
@media only screen and (min-width: $xl-width) {
  #clinical-excellence-item {}
}


/* --- LARGE SCREEN STYLES - XXL --- */
@media only screen and (min-width: $xxl-width) {
  #clinical-excellence-item {}
}