/* --- Notes --- */



/* --- Start SCSS --- */

.image-video-block {
  display: grid;
  grid-gap: 25px;
}



/* --- TABLET STYLES - SM --- */
@media only screen and (min-width: $sm-width) {
  .image-video-block {}
}



/* --- TABLET STYLES - MD --- */
@media only screen and (min-width: $md-width) {
  .image-video-block {}
}



/* --- DESKTOP STYLES - LG --- */
@media only screen and (min-width: $lg-width) {
  .image-video-block {}
}



/* --- LARGE SCREEN STYLES - XL --- */
@media only screen and (min-width: $xl-width) {
  .image-video-block {}
}


/* --- LARGE SCREEN STYLES - XXL --- */
@media only screen and (min-width: $xxl-width) {
  .image-video-block {}
}