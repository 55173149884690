/* --- Notes --- */



/* --- Start SCSS --- */

#job-listing {
  .listing-wrapper {
    display: grid;
    grid-gap: 20px;
    margin-top: -20px;

    .details {
      display: grid;
      grid-gap: 5px;
      background: $vl-grey;
      padding: 15px;
      @include p-small;
      margin-bottom: 0;
      font-weight: 700;
      margin-bottom: 10px;

      li {
        display: inline-block;
      }

      li:last-of-type {
        text-decoration: underline;
        cursor: pointer;
        margin-right: auto;

        &:hover {
          color: $l-grey;
        }
      }
    }

    .introduction {
      @include p-big;
      margin-bottom: 10px;
    }

    .ja-description {
      @include p;
    }

    .apply-btn {
      margin: 20px auto 20px 0;
    }
  }

  .region-content {
    position: relative;
    padding-top: 20px;
    padding-bottom: 20px;

    &:before,
    &:after {
      content: '';
      width: 100%;
      height: 1px;
      background-color: $m-grey;
      position: absolute;
    }

    &:before {
      top: 0px;
    }

    &:after {
      bottom: 0px;
    }
  }

  .image-video-wrapper,
  .region-content-wrapper,
  .form-wrapper {
    padding-top: 0;
  }

  #registrationForm {
    margin-top: 20px;
    padding: 20px 15px;
    grid-gap: 25px;
    // margin-top: 20px;
    // padding: 30px;
    // grid-gap: 25px;
    background-color: $vl-grey;

    .input-wrapper {
      display: grid;
      grid-gap: 10px;
    }

    .upload-wrapper {
      .footnote {
        @include update-font(12px, 17px);
        margin: 2px 0;
      }
    }

    .terms-wrapper {
      a {
        color: $d-grey;
        text-decoration: underline;

        &:hover {
          color: $l-grey;
        }
      }
    }

    .submit-wrapper {
      margin: 10px 0 0 0;
      display: grid;

      .btn {
        margin-right: auto;
      }
    }
  }
}



/* --- TABLET STYLES - SM --- */
@media only screen and (min-width: $sm-width) {
  #job-listing {
    #registrationForm {
      margin-top: 20px;
      padding: 30px;
      grid-gap: 25px;

      .upload-wrapper {
        input { width: auto;}
      }

      .submit-wrapper {
        grid-gap: 15px;
        grid-template-columns: auto 1fr;

        .recaptcha-byline {
          margin: auto 0;
        }
      }
    }
  }
}



/* --- TABLET STYLES - MD --- */
@media only screen and (min-width: $md-width) {
  #job-listing {
    .listing-wrapper {
      margin-top: -50px;

      .details {
        grid-template-columns: auto auto;
        grid-template-rows: auto auto auto;
        grid-auto-flow: column;
        grid-column-gap: 10px;
        margin-bottom: 25px;
      }
    }


  }
}



/* --- DESKTOP STYLES - LG --- */
@media only screen and (min-width: $lg-width) {
  #job-listing {

    .listing-wrapper {
      .details {
        grid-template-columns: auto auto auto;
        grid-template-rows: auto auto;
      }
    }
  }
}



/* --- LARGE SCREEN STYLES - XL --- */
@media only screen and (min-width: $xl-width) {
  #job-listing {}
}


/* --- LARGE SCREEN STYLES - XXL --- */
@media only screen and (min-width: $xxl-width) {
  #job-listing {}
}