/* --- Notes --- */



/* --- Start SCSS --- */

#community {
  .intro-wrapper {
    display: grid;
    grid-gap: 40px;

    .intro {
      display: grid;
      grid-gap: 20px;
    }

    .wysiwyg {
      max-width: 750px;
    }
  }

  .accordion-inner {
    display: grid;
    grid-gap: 25px;

    .wysiwyg {
      padding-bottom: 10px;
    }

    .assets-wrapper {
      display: grid;
      grid-gap: 25px;
    }
  }
}



/* --- TABLET STYLES - SM --- */
@media only screen and (min-width: $sm-width) {
  #community {}
}



/* --- TABLET STYLES - MD --- */
@media only screen and (min-width: $md-width) {
  #community {
    .intro-wrapper {
      grid-gap: 60px;

      .wysiwyg { max-width: 75% }
    }

    .accordion-inner {
      grid-template-columns: 2fr 1fr;
      grid-row-gap: 40px;

      .wysiwyg {
        margin-bottom: auto;
      }

      .assets-wrapper {
        grid-gap: 10px;
        margin-bottom: auto;
      }

      .statement-module {
        grid-column: 1 / -1;
      }
    }
  }
}



/* --- DESKTOP STYLES - LG --- */
@media only screen and (min-width: $lg-width) {
  #community {}
}



/* --- LARGE SCREEN STYLES - XL --- */
@media only screen and (min-width: $xl-width) {
  #community {}
}


/* --- LARGE SCREEN STYLES - XXL --- */
@media only screen and (min-width: $xxl-width) {
  #community {}
}