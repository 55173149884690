/* --- Notes --- */



/* --- Start SCSS --- */


#error {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  background-color: $vl-grey;

  .inner {
    margin: auto;
    padding: 20px;
    max-width: 410px;
  }

  h1 {
    color: $d-grey;
  }

  p {
    margin-top: 7px;
    margin-bottom: 15px;
    @include update-font(18px,24px);
  }


  .links {
    @include p;

    a {
      color: $d-grey;
      padding: 2px 0 3px 0;
      display: inline-block;

      @media (hover) {
        &:hover {
          color: $l-grey;
        }
      }
    }
  }
}



/* --- TABLET STYLES - SM --- */
@media only screen and (min-width: $sm-width) {
  #error {}
}



/* --- TABLET STYLES - MD --- */
@media only screen and (min-width: $md-width) {
  #error {}
}



/* --- DESKTOP STYLES - LG --- */
@media only screen and (min-width: $lg-width) {
  #error {}
}



/* --- LARGE SCREEN STYLES - XL --- */
@media only screen and (min-width: $xl-width) {
  #error {}
}