/* --- Notes --- */



/* --- Start SCSS --- */

#submenu {
  position: sticky;
  top: $xs-menu;
  z-index: 10;
  background-color: $white;
  padding: 0;

  ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 -10px;
    padding: 0;
    list-style: none;

    li {
      @include font(11px, 11px, $d-grey, 700);
      text-transform: uppercase;
      cursor: pointer;
      flex: 0;
      white-space: nowrap;
      padding: 15px 10px;

      &:hover:not(.active) {
        color: $l-grey;
      }

      &.active {
        position: relative;

        &:after {
          content: "";
          @include centerX;
          bottom: 15px - 4px;
          display: block;
          width: calc(100% - 20px);
          height: 1px;
          background-color: $d-grey;
        }
      }

      &.spacer {
        flex: 1;
        color: rgba($l-grey, 0.5);
        font-weight: 400;
        text-align: center;
        cursor: default;
        padding: 0;
      }
    }
  }
}



/* --- TABLET STYLES - SM --- */
@media only screen and (min-width: $sm-width) {
  #submenu {
    height: 50px;

    ul {
      margin: 0;
      @include centerX;

      li {
        @include update-font(12px, 12px);
        padding: 20px 30px;

        &.active:after {
          bottom: 20px - 4px;
          width: calc(100% - 60px);
        }
      }
    }
  }
}



/* --- TABLET STYLES - MD --- */
@media only screen and (min-width: $md-width) {
  #submenu {
    top: $md-menu;
  }
}



/* --- DESKTOP STYLES - LG --- */
@media only screen and (min-width: $lg-width) {
  #submenu {
    top: $lg-menu - 10px;

    &.is-pinned {
      ul {
        right: -30px;
        left: auto;
        transform: none;
      }
    }

    ul {
      transition: 600ms;
    }
  }
}



/* --- LARGE SCREEN STYLES - XL --- */
@media only screen and (min-width: $xl-width) {
  #submenu {}
}


/* --- LARGE SCREEN STYLES - XXL --- */
@media only screen and (min-width: $xxl-width) {
  #submenu {}
}